<template>
  <div class="input">
		<input type="text" class="input__input" :id="id" :name="id" v-model="input" :placeholder="placeholder" autocomplete="off" />
		<label :for="id" :class="['input__label',{'input__label--error' : error}]">{{label}}</label>
	</div>
</template>

<script>
export default {
	props: {
		label: String,
		id: String,
		placeholder:  {
      type: String,
      default: ' ',
    },
		error: Boolean,
	},
  data() {
    return {
      input: '',
    };
  },
  watch: {
    input: function() {
      this.$emit("input", this.input);
    }
  },
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.input{
	width: var(--input-width);
	margin-bottom: var(--input-margin-bottom);
	margin-top: var(--input-margin-top);
	position: relative;
}
.input__label{
	font-family: var(--input-label-font-family);
	color: var(--input-label-color);
	font-size: var(--input-label-font-size);
	font-weight: var(--input-label-font-weight);
	margin-left: var(--input-label-margin-left);
	bottom: var(--input-label-bottom);
	transition: var(--input-label-transition);
	position: var(--input-label-position);
	display: block;
	z-index: 1;
}
.input__label--error{
	color: var(--textarea-label-error-color);
}
.input__input{
	font-family: var(--input-input-font-family);
	color: var(--input-input-color);
	background: var(--input-input-background);
	font-size: var(--input-input-font-size);
	width: var(--input-input-width);
	padding: var(--input-input-padding);
	border-top: var(--input-input-border-top);
	border-right: var(--input-input-border-right);
	border-bottom: var(--input-input-border-bottom);
	border-left: var(--input-input-border-left);
	margin-bottom: var(--input-input-margin-bottom);
	line-height: var(--input-input-line-height);
}
.input__input:-webkit-autofill,
.input__input:-webkit-autofill:hover, 
.input__input:-webkit-autofill:focus, 
.input__input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 40px var(--input-input-background) inset !important;
  box-shadow: 0 0 0 40px var(--input-input-background) inset !important;
	color: var(--input-input-color)!important;
}
.input__input:focus-visible{
	outline: none;
}
.input__input:focus + .input__label{
	bottom: var(--input-focus-label-bottom);
	font-size: var(--input-focus-label-font-size);
}
.input__input:not(:placeholder-shown) + .input__label{
	bottom: var(--input-focus-label-bottom);
	font-size: var(--input-focus-label-font-size);
}

</style>

<!-- Used in : AR, SD -->